import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MainSerReqComponent } from './main-ser-req/main-ser-req.component';
import { MatTabsModule } from '@angular/material/tabs';
import { ServiceDetailsComponent } from './serv-details/serv-details.component';
import { MatIconModule } from '@angular/material/icon';
import { MatMenuModule } from '@angular/material/menu';
import { MatFormFieldModule } from '@angular/material/form-field';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInputModule } from '@angular/material/input';
import { MatCardModule } from '@angular/material/card';
import { MatSelectModule } from '@angular/material/select';

@NgModule({
  declarations: [MainSerReqComponent, ServiceDetailsComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    MatIconModule,
    MatMenuModule,
    MatFormFieldModule,
    FormsModule,
    ReactiveFormsModule,
    MatInputModule,
    MatCardModule,
    MatSelectModule
  ],
})
export class ServiceRequestModule {}
