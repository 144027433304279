import { Component, OnInit } from '@angular/core';
import { ServiceMangeService } from 'src/app/services/service-mange.service';
import { MainService } from 'src/app/services/main.service';

@Component({
  selector: 'app-serv-details',
  templateUrl: './serv-details.component.html',
  styleUrls: ['./serv-details.component.css'],
})
export class ServiceDetailsComponent implements OnInit {
  showAddingMicroServiceData: boolean = false;
  amount: number = 0;
  price: number = 100;

  service_obj: any = {
    description: '',
    quantity: '',
    date: '',
    time: '',
    status: '',
    name: '',
    unit: '',
    servName: '',
    servImage: '',
    images: [],
  };
  serv_obj: any;
  constructor(
    private rest: ServiceMangeService,
    private mainService: MainService
  ) {}

  ngOnInit() {
    this.serv_obj = localStorage.getItem('servItem');
    this.serv_obj = JSON.parse(this.serv_obj);
    this.service_obj.description = this.serv_obj.description;
    this.service_obj.quantity = this.serv_obj.quantity;
    this.service_obj.status = this.serv_obj.status;
    this.service_obj.name = this.serv_obj.client_name;
    this.service_obj.unit = this.serv_obj.unit_name;
    this.service_obj.servName = this.serv_obj.service.name;
    this.service_obj.servImage = this.serv_obj.service.image;
    this.service_obj.images = this.serv_obj.images;
    let str_arr: any = this.serv_obj.timestamp.split(' ');
    this.service_obj.date = str_arr[0];
    this.service_obj.time = str_arr[1];
  }

  changeStatus(value: any) {
    this.service_obj.status = value;
    let data = {
      request_id: this.serv_obj.id,
      status: value,
    };

    this.rest.requestUpdate(data).subscribe((res: any) => {
      this.mainService.SuccessToast('Service Request Updated');
    });
  }

  showMicroServices() {
    this.showAddingMicroServiceData = true;
  }

  increasePrice() {
    this.price += 100;
  }

  decreasePrice() {
    if (this.price > 0) {
      this.price -= 100;
    }
  }

  increaseAmount() {
    this.amount += 1;
  }

  decreaseAmount() {
    if (this.amount > 1) {
      this.amount -= 1;
    }
  }
}
