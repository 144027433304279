import { Injectable } from '@angular/core';
import { environment } from '../../environments/environments';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root',
})
export class ServiceMangeService {
  constructor(private http: HttpClient) {}

  allServices() {
    return this.http.get(`${environment.baseUrl}/services/`);
  }

  microService(page: number, limit: number, id: any) {
    return this.http.get(
      `${environment.baseUrl}/services/microservices/?service_id=${id}&page=${page}&limit=${limit}`
    );
  }

  addMicro_service(data: any) {
    var formData = new FormData();
    formData.append('type_id', data.type_id);
    formData.append('name', data.name);
    formData.append('image', data.image);
    formData.append('cost', data.cost);
    let auth_token = localStorage.getItem('acc_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth_token}`);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };
    return fetch(
      `${environment.baseUrl}/services/microservices/`,
      requestOptions
    );
  }
  add_service(data: any) {
    var formData = new FormData();
    formData.append('name', data.name);
    formData.append('image', data.image);
    let auth_token = localStorage.getItem('acc_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth_token}`);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };
    return fetch(`${environment.baseUrl}/services/`, requestOptions);
  }
  add_Service_Schedules(data: any) {
    return this.http.post(`${environment.baseUrl}/services/booking/`, data);
  }
  edit_service(data: any) {
    var formData = new FormData();
    formData.append('name', data.name);
    formData.append('image', data.image);
    formData.append('service_id', data.service_id);
    if (data.cost) {
      formData.append('cost', data.cost);
    }
    let auth_token = localStorage.getItem('acc_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth_token}`);

    var requestOptions: any = {
      method: 'PUT',
      headers: myHeaders,
      body: formData,
      redirect: 'follow',
    };
    return fetch(`${environment.baseUrl}/services/`, requestOptions);
  }

  serviceRequest(type: any) {
    return this.http.get(
      `${environment.baseUrl}/services/servicerequest/?unit_id=1&status=${type}`
    );
  }

  requestUpdate(data: any) {
    return this.http.put(
      `${environment.baseUrl}/services/update_request/`,
      data
    );
  }

  disableServices(data: any) {
    return this.http.put(`${environment.baseUrl}/services/disable/`, data);
  }

  // utilty

  retrieve_all_utilities() {
    return this.http.get(`${environment.baseUrl}/utility/all`);
  }

  addUtility(data: any) {
    return this.http.post(`${environment.baseUrl}/utility/`, data);
  }

  editUtility(data: any) {
    return this.http.put(`${environment.baseUrl}/utility/`, data);
  }

  addSection(data: any) {
    return this.http.post(`${environment.baseUrl}/utility/section/`, data);
  }

  editSection(data: any) {
    return this.http.put(`${environment.baseUrl}/utility/section/`, data);
  }

  addUtitlyImage(data: any) {
    var formdata = new FormData();
    formdata.append('utility_id', data.utility_id);
    formdata.append('image', data.image);

    let auth_token = localStorage.getItem('acc_token');
    var myHeaders = new Headers();
    myHeaders.append('Authorization', `Bearer ${auth_token}`);

    var requestOptions: any = {
      method: 'POST',
      headers: myHeaders,
      body: formdata,
      redirect: 'follow',
    };
    return fetch(`${environment.baseUrl}/images/utility/`, requestOptions);
  }

  disabledUtlity(data: any) {
    return this.http.put(`${environment.baseUrl}/utility/disable/`, data);
  }

  addInvoice(data: any) {
    return this.http.post(`${environment.baseUrl}/invoices/utilities/`, data);
  }
}
