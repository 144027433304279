<section class="main">
  <section class="sup_main">
    <div class="d-flex justify-content-between align-items-center">
      <h1>Service request</h1>

      <div class="d-flex justify-content-between align-items-center">
        <button
          class="btn me-2"
          (click)="showSearchInput()"
          *ngIf="!showSearchInputField"
        >
          <mat-icon class="me-2">search</mat-icon> Search Unit ID
        </button>

        <mat-form-field
          class="me-2"
          appearance="outline"
          *ngIf="showSearchInputField"
        >
          <mat-label>Search by unit ID</mat-label>
          <input (input)="searchUnit($event)" matInput type="text" />
        </mat-form-field>

        <button mat-icon-button [matMenuTriggerFor]="menu" class="btn">
          <mat-icon>filter_list</mat-icon>
        </button>
        <mat-menu #menu="matMenu" xPosition="before">
          <button mat-menu-item>
            <span>AC Service</span>
          </button>
          <button mat-menu-item>
            <span>Cleaning</span>
          </button>
          <button mat-menu-item>
            <span>Pump</span>
          </button>
          <button mat-menu-item>
            <span>Electronics</span>
          </button>
        </mat-menu>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-12">
        <mat-tab-group>


          <mat-tab label="Pending">
            <div class="row mt-3 containerItems">
              <div class="col-6 mb-2" *ngFor="let item of pending_arr">
                <div
                  class="cards d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3 img-fluid"
                      style="width: 60px; height: 60px"
                      src="{{ item.service.image }}"
                    />
                    <div>
                      <p class="mb-3 title">{{ item.service.name }}</p>
                      <div class="d-flex align-items-center">
                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/profile.png"
                          />
                          <p class="client_name">{{ item.client_name }}</p>
                        </span>

                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/house.png"
                          />
                          <p class="client_name">{{ item.unit_name }}</p>
                        </span>

                        <span class="statusPending d-flex align-items-center">
                          <p>{{ item.status }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer" (click)="request_details(item)">
                    <img src="assets/images/serivces/arrow-right.png" />
                  </div>
                </div>
              </div>
              <h1 *ngIf="pending_arr.length == 0">No data Found</h1>
            </div>
          </mat-tab>
          <mat-tab label="Active">
            <div class="row mt-3 containerItems">
              <div class="col-6" *ngFor="let item of active_arr">
                <div
                  class="cards d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ item.service.image }}"
                    />
                    <div>
                      <p class="mb-3 title">{{ item.service.name }}</p>
                      <div class="d-flex align-items-center">
                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/profile.png"
                          />
                          <p class="client_name">{{ item.client_name }}</p>
                        </span>

                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/house.png"
                          />
                          <p class="client_name">{{ item.unit_name }}</p>
                        </span>

                        <span class="statusActive d-flex align-items-center">
                          <p>{{ item.status }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer" (click)="request_details(item)">
                    <img src="assets/images/serivces/arrow-right.png" />
                  </div>
                </div>
              </div>
              <h1 *ngIf="active_arr.length == 0">No data Found</h1>
            </div>
          </mat-tab>


          
          <mat-tab label="Closed">
            <!-- <div class="row mt-3 containerItems">
              <div class="col-6" *ngFor="let item of closed_arr">
                <div
                  class="cards d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ item.service.image }}"
                    />
                    <div>
                      <p class="mb-3 title">{{ item.service.name }}</p>
                      <div class="d-flex align-items-center">
                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/profile.png"
                          />
                          <p class="client_name">{{ item.client_name }}</p>
                        </span>

                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/house.png"
                          />
                          <p class="client_name">{{ item.unit_name }}</p>
                        </span>

                        <span class="statusClosed d-flex align-items-center">
                          <p>{{ item.status }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer" (click)="request_details(item)">
                    <img src="assets/images/serivces/arrow-right.png" />
                  </div>
                </div>
              </div>
              <h1 *ngIf="closed_arr.length == 0">No data Found</h1>
            </div> -->

            <section class="tableUnit table-responsive d-none d-md-block">
              <table class="table">
                <thead>
                  <tr>
                    <th scope="col">Client name</th>
                    <th scope="col">Unit name</th>
                    <th scope="col">Type of Service</th>
                    <th scope="col">Request Date</th>
                    <th scope="col">Service Date</th>
                    <th scope="col" class="text-end">Actions</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    (click)="request_details(item)"
                    *ngFor="let item of closed_arr; let i = index"
                    style="vertical-align: middle"
                  >
                    <td>
                      {{ item.client_name }}
                    </td>
                    <td>
                      {{ item.unit_name | titlecase }}
                    </td>
                    <td>
                      {{ item.service?.name }}
                    </td>
                    <td>
                      {{ item.timestamp }}
                    </td>
                    <td>
                      {{ item.schedule }}
                    </td>
                    <td class="text-end">
                      <button
                        mat-icon-button
                        [matMenuTriggerFor]="menu"
                        class="moreDetailsButton"
                      >
                        <mat-icon>more_vert</mat-icon>
                      </button>
                      <mat-menu #menu="matMenu" xPosition="before">
                        <button mat-menu-item (click)="editUnit(item)">
                          <span>Cancel Request</span>
                        </button>
                        <button mat-menu-item (click)="editUnit(item)">
                          <span>Client Profile</span>
                        </button>
                      </mat-menu>
                    </td>
                  </tr>
                </tbody>
              </table>
              <h3 *ngIf="closed_arr == null">No Data Found</h3>
            </section>
          </mat-tab>
          <mat-tab label="Complete">
            <div class="row mt-3 containerItems">
              <div class="col-6" *ngFor="let item of complete_array">
                <div
                  class="cards d-flex justify-content-between align-items-center"
                >
                  <div class="d-flex align-items-center">
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ item.service.image }}"
                    />
                    <div>
                      <p class="mb-3 title">{{ item.service.name }}</p>
                      <div class="d-flex align-items-center">
                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/profile.png"
                          />
                          <p class="client_name">{{ item.client_name }}</p>
                        </span>

                        <span class="me-4 d-flex align-items-center">
                          <img
                            class="me-1"
                            src="assets/images/serivces/house.png"
                          />
                          <p class="client_name">{{ item.unit_name }}</p>
                        </span>

                        <span class="statusComplet d-flex align-items-center">
                          <p>{{ item.status }}</p>
                        </span>
                      </div>
                    </div>
                  </div>
                  <div style="cursor: pointer" (click)="request_details(item)">
                    <img src="assets/images/serivces/arrow-right.png" />
                  </div>
                </div>
              </div>
              <h1 *ngIf="complete_array.length == 0">No data Found</h1>
            </div>
          </mat-tab>


        </mat-tab-group>
      </div>
    </div>
  </section>
</section>
