import { Component, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { ClientsService } from 'src/app/services/clients.service';
import { PageEvent } from '@angular/material/paginator';
import { AddCardComponent } from '../../units/add-card/add-card.component';
import { MatDialog } from '@angular/material/dialog';
import { UnitBlockComponent } from '../../units/unit-block/unit-block.component';
import { UnitsService } from 'src/app/services/units.service';
import { MainService } from 'src/app/services/main.service';
import { InvtaionComponent } from '../../units/invtaion/invtaion.component';
import { QrInvtationComponent } from '../../units/qr-invtation/qr-invtation.component';
import { DatePipe } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { AddNoteComponent } from '../../units/add-note/add-note.component';
import { ConfigurationsService } from 'src/app/services/configurations.service';
import { environment } from 'src/environments/environments';
import { tap } from 'rxjs';
import { UnitUnpaidInvoicesComponent } from '../../units/unit-unpaid-invoices/unit-unpaid-invoices.component';
import { PrintConfirmationModalComponent } from '../../units/print-confirmation-modal/print-confirmation-modal.component';
import { RegisterAccessComponent } from '../../units/register-access/register-access.component';
import { UnpaidInvoicesComponent } from '../../units/unpaid-invoices/unpaid-invoices.component';

@Component({
  selector: 'app-all-clients',
  templateUrl: './all-clients.component.html',
  styleUrls: ['./all-clients.component.css'],
})
export class AllClientsComponent implements OnInit {
  all_clients: any = [];
  page = 1;
  limit = 10;
  totalCount = 0;
  pipe = new DatePipe('en-US');
  imageBase64: any;
  imageBase641: any;
  printingCardOrientation: any = environment.printingCardOrientation;

  configurationPage = 1;
  configurationLimit = 0;
  allConfiguration: any;
  frontPhoto: any;
  backPhoto: any;

  showSpinner: boolean = true;
  clientSearchKey: string | any = '';
  clientUnitId: any;
  displayedColumns: string[] = [
    'userImage',
    'userName',
    'userUnitDetails',
    'action',
  ];

  hidden = false;

  clientIdArrayForPrinting: any;
  todayDate: any;
  @ViewChild('printModal') printModal!: PrintConfirmationModalComponent;

  constructor(
    private route: Router,
    public dialog: MatDialog,
    private _sanitizer: DomSanitizer,
    private mainService: MainService,
    private rest: UnitsService,
    private http: HttpClient,
    private clientService: ClientsService,
    private readonly configuration: ConfigurationsService
  ) {}

  ngOnInit() {
    this.showSpinner = true;
    this.allClients();

    this.http
      .get('/assets/images/fouka.jpg', { responseType: 'blob' })
      .subscribe((res: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          var base64data = reader.result;
          this.imageBase64 = base64data;
          this.allClients();
        };
        reader.readAsDataURL(res);
      });
    this.http
      .get('/assets/images/foukaone.jpg', { responseType: 'blob' })
      .subscribe((res: any) => {
        const reader = new FileReader();
        reader.onloadend = () => {
          var base64data = reader.result;
          this.imageBase641 = base64data;
        };
        reader.readAsDataURL(res);
      });
    // this.clientSearch()
    this.getAllConfigurations();

    if (localStorage.getItem('clientIdArrayForPrinting')) {
      this.clientIdArrayForPrinting = localStorage.getItem(
        'clientIdArrayForPrinting'
      );
      this.clientIdArrayForPrinting = JSON.parse(this.clientIdArrayForPrinting);

      setTimeout(() => {
        this.printModal.show();
      }, 1000);
    }
    this.checkTodayDate();
  }

  checkTodayDate() {
    const currentDate = new Date();
    this.todayDate = this.pipe.transform(currentDate, 'yyyy-MM-dd');
  }

  allClients() {
    this.cardObject.image1 = this.imageBase64;
    this.clientService
      .allClients(this.page, this.limit, this.clientSearchKey)
      .subscribe((res: any) => {
        this.showSpinner = false;
        this.all_clients = res?.results;
        this.totalCount = res?.total_count;
        this.all_clients.forEach((element: any) => {
          if (element.images.length != 0) {
            element.images.forEach((item: any) => {
              item.image = this._sanitizer.bypassSecurityTrustUrl(
                `data:image/png;base64,` + item.image
              );
              if (item.image_type == 'personal_photo') {
                element.photo = item.image;
              }
            });
          } else {
            element.images.push({
              image: 'assets/images/avatar.png',
            });
          }
        });
      });
  }

  // clientNameSearch = '';
  clientSearch(clientSearchEvent: any) {
    this.showSpinner = true;
    this.page = 1;
    this.limit = 9;
    this.clientSearchKey = clientSearchEvent?.target?.value;
    this.allClients();

    // this.showSpinner = true;
    // this.clientNameSearch = clientSearchEvent.target.value;
    // this.clientService
    //   .searchClient(this.clientNameSearch, this.page, this.limit)
    //   .subscribe((res: any) => {
    //     this.showSpinner = false;
    //     this.all_clients = res;
    //     this.all_clients.forEach((element: any) => {
    //       if (element.images.length != 0) {
    //         element.images.forEach((item: any) => {
    //           item.image = this._sanitizer.bypassSecurityTrustUrl(
    //             `data:image/png;base64,` + item.image
    //           );
    //           if (item.image_type == 'personal_photo') {
    //             element.photo = item.image;
    //           }
    //         });
    //       } else {
    //         element.images.push({
    //           image: 'assets/images/avatar.png',
    //         });
    //       }
    //     });
    //   });
  }
  Details() {
    this.route.navigateByUrl('pages/clients_payment');
  }
  clientLogs() {
    this.route.navigateByUrl('pages/clients_log');
  }

  handlePageEvent(e: PageEvent) {
    this.showSpinner = true;
    this.page = e?.pageIndex + 1;
    this.limit = e.pageSize;
    this.allClients();

    // if (this.clientNameSearch != '') {
    //   this.clientService
    //     .searchClient(this.clientNameSearch, this.page, this.limit)
    //     .subscribe((res: any) => {
    //       this.all_clients = res;
    //     });
    // } else {
    //   this.allClients();
    // }
  }

  getAllConfigurations() {
    this.configuration
      .retrieveConfigurations(this.page, this.limit)
      .subscribe((data: any) => {
        this.allConfiguration = data.results;
        this.allConfiguration.forEach((element: any) => {
          if (element.images?.length != 0) {
            element.images?.forEach((item: any) => {
              if (item.image_type == 'front') {
                element.frontPhoto = item.image;
              } else if (item.image_type == 'back') {
                element.backPhoto = item.image;
              }
            });
          }
        });
      });
  }

  addCard(value: any) {
    const dialogRef = this.dialog.open(AddCardComponent, {
      width: '450px',
      data: { id: value.id },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.client_id = value.id;

        this.rest.addCard(result).subscribe((res: any) => {
          if (res.status == false) {
            this.mainService.errorToast(res?.message);
          } else {
            this.activeClient(value.id);
            this.mainService.SuccessToast('Card Added');
          }
        });
      }
    });
  }
  activeClient(value: any) {
    let data = {
      id: value + '',
      activated: true,
    };
    this.clientService.editClient(data).subscribe((res: any) => {
      this.mainService.SuccessToast('Client Activated');
      this.allClients();
    });
  }

  blockUnitGates(id: any) {
    let unitOrClientID: any;
    let unitOrClientName: any;
    if (id != null) {
      unitOrClientID = id;
      unitOrClientName = 'client';
    } else {
      unitOrClientID = '';
      unitOrClientName = 'unit';
    }
    const dialogRef = this.dialog.open(UnitBlockComponent, {
      width: '400px',
      data: { id: unitOrClientID, name: unitOrClientName },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        if (id != null) {
          let data = {
            gates: result,
            clients: [id],
          };
          if (result.balckList.length != 0) {
            data.gates = result.balckList;
            this.rest.blackListForClient(data).subscribe((res: any) => {
              this.mainService.SuccessToast('Client add in blackList Gates');
            });
          } else {
            data.gates = result.WhiteList;
            this.rest.whiteListForClient(data).subscribe((res: any) => {
              this.mainService.SuccessToast('Client add in whiteList Gates');
            });
          }
        }
      }
    });
  }

  generateInvitation(value: any) {
    const dialogRef = this.dialog.open(InvtaionComponent, {
      width: '450px',
      data: {
        unitName: value.unit[0].name,
        clientName: value.name,
        unitId: value.unit[0].id,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.data.unit_id = value.unit[0].id;
        result.data.client_id = value.id;
        result.data.valid_from = this.pipe.transform(
          result.data.valid_from,
          'yyyy-MM-dd hh:mm:ss'
        );
        result.data.valid_to = this.pipe.transform(
          result.data.valid_to,
          'yyyy-MM-dd hh:mm:ss'
        );
        if (result.value == 'normal') {
          this.rest.clientInvitation(result.data).subscribe(
            (res: any) => {
              let data: any = result;
              data.qrName = res.qr_code;
              data.unitName = value.unit[0].name;
              (data.inviter = value.name), this.openQr(data);
            },
            (err: any) => {
              this.mainService.errorToast(err?.error?.message);
            }
          );
        } else {
          this.rest.clientSmsInvite(result.data).subscribe(
            (res: any) => {},
            (err: any) => {
              this.mainService.errorToast(err?.error?.message);
            }
          );
        }
      }
    });
  }
  openQr(value: any) {
    const dialogRef = this.dialog.open(QrInvtationComponent, {
      width: '320px',
      data: value,
    });
  }

  cardObject = {
    name: '',
    unitName: '',
    validFrom: '',
    validTo: '',
    type: '',
    image: '',
    image1: 'assets/images/teamCard.jpg',
    frontImage: '',
    villageName: '',
    status: false,
  };
  dataPrint: any = [];
  userTypeForPrinting: any;
  orientationPrinting: any;

  checkUserType(type: any) {
    for (const obj of this.printingCardOrientation) {
      if (obj.userType === type) {
        this.orientationPrinting = obj.orientation;
      }
    }
  }

  print_card(data: any) {
    this.userTypeForPrinting = data?.type?.name;
    this.checkUserType(this.userTypeForPrinting);
    this.dataPrint = [];
    this.cardObject.name = data.name;
    // this.cardObject.type = data.type.name + "'s" + ' ' + 'Card';
    if (data.type.name == 'Main Owner') {
      this.cardObject.type = 'owner' + "'s" + ' ' + 'Card';
    } else {
      this.cardObject.type = data.type.name + "'s" + ' ' + 'Card';
    }
    this.cardObject.validFrom = data.valid_from;
    this.cardObject.validTo = data.valid_to;
    this.cardObject.villageName = data?.unit[0]?.village.name;
    if (data.unit.length != 0) {
      this.cardObject.unitName = data.unit[0].name;
    } else {
      this.cardObject.unitName = '';
    }
    // if (data.type.name == 'Main Owner' || data.type.name == 'Owner') {
    //   this.cardObject.image1 = this.imageBase641;
    //   this.cardObject.status = true;
    // } else {
    //   this.cardObject.image1 = this.imageBase64;
    //   this.cardObject.status = false;
    // }

    this.findPhotosByType(this.allConfiguration, data?.type?.name);
    this.cardObject.image1 = this.backPhoto;
    this.cardObject.frontImage = this.frontPhoto;

    if (data.photo) {
      this.cardObject.image = data.photo;
    } else {
      this.cardObject.image = 'assets/images/avatar.png';
    }
    this.dataPrint.push(this.cardObject);

    setTimeout(() => {
      this.clientService
        .printCardForClient(data.id, true)
        .subscribe((res: any) => {
          this.mainService.SuccessToast('Printed success');
          this.allClients();
        });
      const button: any = document.getElementById('printButton');
      button.click();
    }, 200);
  }

  findPhotosByType(configurationArray: any, userType: any) {
    this.frontPhoto = null;
    this.backPhoto = null;

    for (const obj of configurationArray) {
      if (obj.type === userType) {
        this.frontPhoto = obj.frontPhoto;
        this.backPhoto = obj.backPhoto;
        break;
      }
    }
  }

  allOtherInvoices: any;
  unPaidOtherInvoices: any[] = [];

  getAllInvoices() {
    return this.rest.unit_invoice(this.clientUnitId).pipe(
      tap((res: any) => {
        this.allOtherInvoices = res?.others;
        this.unPaidOtherInvoices = this.allOtherInvoices.filter(
          (item: any) =>
            item?.purchase_name?.includes('Access') &&
            item?.paid === false &&
            item?.created_at?.includes(this.todayDate)
        );
      })
    );
  }

  print_card_check_invoices(data: any) {
    this.clientUnitId = data?.unit[0]?.id;
    this.getAllInvoices().subscribe({
      next: (response: any) => {
        console.log(response);
        if (this.unPaidOtherInvoices?.length) {
          const dialogRef = this.dialog.open(UnitUnpaidInvoicesComponent, {
            width: '700px',
            maxHeight: '700px',
            data: {
              unPaidOtherInvoices: this.unPaidOtherInvoices,
              unitId: this.clientUnitId,
            },
          });

          dialogRef.afterClosed().subscribe((resultComeFromPayment: any) => {
            if (resultComeFromPayment?.status) {
              this.newPrintingScenario([data]);
            }
          });
        } else {
          this.newPrintingScenario([data]);
        }
      },
      error: (err) => {
        if (err?.status == 406) {
          this.newPrintingScenario([data]);
        }
      },
    });
  }

  print_card_again(data: any) {
    this.clientUnitId = data.unit[0].id;

    const dialogRef = this.dialog.open(RegisterAccessComponent, {
      width: '450px',
      data: {
        unitId: this.clientUnitId,
        clientId: data?.id,
      },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        this.rest.registerAccessService(result).subscribe((res: any) => {
          if (res.status == false) {
            this.mainService.errorToast(res?.message);
          } else {
            if (res.paid) {
              if (result.clients) {
                // this.usersToBePrinted = this.filterArrayByIds(
                //   this.unit_clients,
                //   result.clients
                // );
                this.newPrintingScenario([data]);
              } else if (!result.clients) {
                this.showSpinner = true;
                this.allClients();
              }
            } else {
              const dialogRef = this.dialog.open(UnpaidInvoicesComponent, {
                width: '650px',
                data: {
                  result: res,
                },
              });

              dialogRef
                .afterClosed()
                .subscribe((resultComeFromPayment: any) => {
                  if (resultComeFromPayment?.status) {
                    if (result.clients) {
                      // this.usersToBePrinted = this.filterArrayByIds(
                      //   this.unit_clients,
                      //   result.clients
                      // );

                      this.newPrintingScenario([data]);
                    } else if (!result.clients) {
                      this.showSpinner = true;
                      this.allClients();
                    }
                  } else {
                    this.showSpinner = true;
                    this.allClients();
                  }
                });
            }
          }
        });
      }
    });
  }

  addNote(value: any, id: any) {
    let idOfClientOrUnit;
    idOfClientOrUnit = id;
    const dialogRef = this.dialog.open(AddNoteComponent, {
      width: '450px',
      data: { id: idOfClientOrUnit, name: value },
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      if (result) {
        result.client_id = id;
        (result.expiry_date = this.pipe.transform(new Date(), 'yyyy-MM-dd')),
          this.rest.addNoteForClient(result).subscribe((res: any) => {
            this.allClients();
            this.mainService.SuccessToast('Note Added for the client');
          });
      }
    });
  }

  editClient(value: any) {
    this.route.navigate(['pages/edit_client', value.unit[0].id, value.id]);
  }

  viewClient(value: any) {
    this.route.navigate(['pages/view_client', value.unit[0].id, value.id]);
  }

  blockClientTitle = 'Block';
  clientIdDeleted: any;
  openBlockClient(title: any, item: any) {
    if (title == 'block') {
      this.blockClientTitle = 'Block';
    } else {
      this.blockClientTitle = 'Active';
    }
    this.clientIdDeleted = item.id;
    const button: any = document.getElementById('btn4');
    button.click();
  }

  blockClient() {
    let data = {
      id: this.clientIdDeleted,
      cancelled: true,
    };
    if (this.blockClientTitle == 'Block') {
      data.cancelled = true;
    } else {
      data.cancelled = false;
    }
    this.clientService.editClient(data).subscribe((res: any) => {
      if (this.blockClientTitle == 'Block') {
        this.mainService.SuccessToast('Client Blocked');
      } else {
        this.mainService.SuccessToast('Client Active');
      }
      this.allClients();
    });
  }

  // delete client

  openDeleteClient(item: any) {
    this.clientIdDeleted = item.id;
    const button: any = document.getElementById('btn3');
    button.click();
  }

  deleteClient() {
    this.rest.deleteClient(this.clientIdDeleted).subscribe((res: any) => {
      this.mainService.SuccessToast('Client deleted');
      this.allClients();
    });
  }

  goToUnit(name: any) {
    this.route.navigate(['pages/unit_details', name]);
  }

  newPrintingScenario(data: any) {
    console.log(data);

    data.forEach((element: any) => {
      // Pushing the ids of printing client in array in the LocalStorage
      let storedArray = localStorage.getItem('clientIdArrayForPrinting');
      let array: number[] = [];
      if (storedArray) {
        array = JSON.parse(storedArray);
      }
      array.push(element.id);
      localStorage.setItem('clientIdArrayForPrinting', JSON.stringify(array));
      // Pushing the ids of printing client in array in the LocalStorage

      let object_data: any = {
        name: element.name,
        unitName: element.unit.length != 0 ? element.unit[0].name : '',
        villageName:
          element.unit.length != 0 ? element.unit[0].village.name : '',
        image1: this.cardObject.image1,
        frontImage: '',
        userType: element.type.name,
        validFrom: this.pipe.transform(element.valid_from, 'dd-MM-yyyy'),
        validTo: this.pipe.transform(element.valid_to, 'dd-MM-yyyy'),
      };
      if (element.type.name == 'Main Owner' || element.type.name == 'Owner') {
        object_data.type = 'owner' + "'s" + ' ' + 'Card';
        object_data.status = true;
      } else {
        object_data.type = element.type.name + "'s" + ' ' + 'Card';
        object_data.status = false;
      }

      if (element.photo) {
        object_data.image = element.photo;
      } else {
        object_data.image = 'assets/images/avatar.png';
      }

      this.checkUserType(element?.type?.name);
      this.findPhotosByType(this.allConfiguration, element?.type?.name);
      object_data.image1 = this.backPhoto;
      object_data.frontImage = this.frontPhoto;
      object_data.orientationPrinting = this.orientationPrinting;

      this.dataPrint.push(object_data);
      // this.clientService
      //   .printCardForClient(element.id)
      //   .subscribe((res: any) => {});
    });
    setTimeout(() => {
      this.printContent('cardPrint');
    }, 200);
  }

  printContent(contentId: string) {
    const printContent = document.getElementById(contentId);
    if (printContent) {
      const originalContents = document.body.innerHTML;
      const printContents = printContent.innerHTML;
      // document.body.innerHTML = printContents;

      document.body.innerHTML = `
      <html>
        <head>
          <title>Print</title>
          <style>
            .clientImage {
              width: 150px !important;
              height: 150px !important;
              object-fit: contain;
              margin-bottom: 30px;
              margin-top: 20px;
            }
          </style>
        </head>
        <body>
          ${printContents}
        </body>
      </html>
    `;

      // Wait for images to load before printing
      const images = Array.from(document.images);
      const loadedImages = images.map((img) => {
        if (img.complete) {
          return Promise.resolve(img);
        } else {
          return new Promise((resolve, reject) => {
            img.onload = () => resolve(img);
            img.onerror = reject;
          });
        }
      });

      Promise.all(loadedImages)
        .then(() => {
          window.onafterprint = () => {
            document.body.innerHTML = originalContents;
            window.location.reload();
          };
          window.print();
        })
        .catch(() => {
          console.error('Failed to load images for printing');
          document.body.innerHTML = originalContents;
          window.location.reload();
        });
    }
  }

  onModalOk() {
    if (this.clientIdArrayForPrinting) {
      this.clientIdArrayForPrinting.forEach((element: any) => {
        if (this.clientIdArrayForPrinting?.length) {
          this.clientService
            .printCardForClient(element, true)
            .subscribe((res: any) => {
              if (res.status) {
                this.mainService.SuccessToast('Printed success');
              } else {
                this.mainService.errorToast(res?.message);
              }
            });
        }
      });
      setTimeout(() => {
        this.allClients();
      }, 200);
      localStorage.removeItem('clientIdArrayForPrinting');
    }
  }

  onModalCancel() {
    if (this.clientIdArrayForPrinting) {
      this.clientIdArrayForPrinting.forEach((element: any) => {
        if (this.clientIdArrayForPrinting?.length) {
          this.clientService
            .printCardForClient(element, false)
            .subscribe((res: any) => {
              if (res.status) {
                this.mainService.infoToast('You did not print the card');
              } else {
                this.mainService.errorToast(res?.message);
              }
            });
        }
      });
      setTimeout(() => {
        this.allClients();
      }, 200);
      localStorage.removeItem('clientIdArrayForPrinting');
    }
  }

  onModalBackground() {
    if (this.clientIdArrayForPrinting) {
      localStorage.removeItem('clientIdArrayForPrinting');
    }
  }
}
