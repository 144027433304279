import { Component, OnInit } from '@angular/core';
import { ServiceMangeService } from 'src/app/services/service-mange.service';
import { Router } from '@angular/router';
import { local } from 'd3-selection';

@Component({
  selector: 'app-main-ser-req',
  templateUrl: './main-ser-req.component.html',
  styleUrls: ['./main-ser-req.component.css'],
})
export class MainSerReqComponent implements OnInit {
  showSearchInputField: boolean = false;
  constructor(private rest: ServiceMangeService, private route: Router) {}

  active_arr: any = [];
  pending_arr: any = [];
  closed_arr: any = [];
  complete_array: any = [];
  ngOnInit() {
    this.getDataActive();
    this.getDataPending();
    this.getDataClosed();
    this.getDataComplet();
  }

  getDataActive() {
    this.rest.serviceRequest('Active').subscribe((res: any) => {
      this.active_arr = res;
    });
  }
  getDataPending() {
    this.rest.serviceRequest('Pending').subscribe((res: any) => {
      this.pending_arr = res;
    });
  }
  getDataClosed() {
    this.rest.serviceRequest('Closed').subscribe((res: any) => {
      this.closed_arr = res;
      console.log(this.closed_arr);
    });
  }
  getDataComplet() {
    this.rest.serviceRequest('Complete').subscribe((res: any) => {
      this.complete_array = res;
    });
  }

  request_details(item: any) {
    localStorage.setItem('servItem', JSON.stringify(item));
    this.route.navigate(['pages/service_request_Details']);
  }

  editUnit(item: any) {}
  searchUnit($event: any) {}

  showSearchInput() {
    this.showSearchInputField = true;
  }
}
