<section class="main">
  <section class="sup_main">
    <h1 class="titleHead">{{ service_obj.servName }}</h1>
    <hr />
    <!-- <div class="d-flex align-items-center sumary">
      <img
        class="me-3"
        style="width: 60px; height: 60px"
        src="{{ service_obj.servImage }}"
      />
      <div>
        <p class="mb-2 ms-1 title">{{ service_obj.servName }}</p>
        <div class="d-flex align-items-center">
          <span class="me-4 d-flex align-items-center">
            <img class="me-1" src="assets/images/serivces/profile.png" />
            <p class="client_name">{{ service_obj.name }}</p>
          </span>

          <span class="me-4 d-flex align-items-center">
            <img class="me-1" src="assets/images/serivces/house.png" />
            <p class="client_name">{{ service_obj.unit }}</p>
          </span>

          <div class="dropdown">
            <button
              class="btn btn-secondary dropdown-toggle"
              type="button"
              id="dropdownMenuButton1"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              {{ service_obj.status }}
            </button>
            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
              <li (click)="changeStatus('Pending')">
                <a class="dropdown-item">Pending</a>
              </li>
              <li (click)="changeStatus('Active')">
                <a class="dropdown-item">Active</a>
              </li>
              <li (click)="changeStatus('Closed')">
                <a class="dropdown-item">Closed</a>
              </li>
              <li (click)="changeStatus('Complete')">
                <a class="dropdown-item">Complete</a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <hr />
    <div class="row">
      <div class="col-6 mb-2">
        <div class="form-group">
          <label>Service name</label>
          <input
            disabled
            value="{{ service_obj.servName }}"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Service category</label>
          <input
            disabled
            value="{{ service_obj.servName }}"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-6 mb-2">
        <div class="form-group">
          <label>Number of rooms</label>
          <input
            disabled
            value="{{ service_obj.quantity }}"
            class="form-control"
            type="text"
          />
        </div>
      </div>
      <div class="col-6"></div>
      <div class="col-6 mb-2">
        <div class="form-group">
          <label>Date</label>
          <input
            disabled
            value="{{ service_obj.date }}"
            class="form-control"
            type="date"
          />
        </div>
      </div>
      <div class="col-6">
        <div class="form-group">
          <label>Time</label>
          <input
            disabled
            value="{{ service_obj.time }}"
            class="form-control"
            type="time"
          />
        </div>
      </div>
      <div class="col-6 mb-2">
        <div class="form-group">
          <label>Description</label>
          <textarea
            disabled
            value="{{ service_obj.description }}"
            class="form-control"
          ></textarea>
        </div>
      </div>
    </div>
    <div class="mt-2" *ngIf="service_obj.images">
      <h2>Photo & Video</h2>
      <div class="row photos">
        <div class="col-2" *ngFor="let item of service_obj.images">
          <img src="{{ item.image }}" class="img-fluid" />
        </div>
      </div>
    </div>

    <div class="mt-2">
      <h2>Voice</h2>
      <audio controls>
        <source src="horse.ogg" type="audio/ogg" />
        <source src="horse.mp3" type="audio/mpeg" />
        Your browser does not support the audio element.
      </audio>
    </div> -->

    <mat-tab-group>
      <mat-tab label="Information">
        <mat-card appearance="outlined">
          <mat-card-content
            class="d-flex align-items-center justify-content-between"
          >
            <div class="d-flex align-items-center sumary">
              <img
                class="me-3"
                style="width: 60px; height: 60px"
                src="{{ service_obj.servImage }}"
              />
              <div>
                <p class="mb-2 ms-1 title">{{ service_obj.servName }}</p>
                <div class="d-flex align-items-center">
                  <span class="me-4 d-flex align-items-center">
                    <img
                      class="me-1"
                      src="assets/images/serivces/profile.png"
                    />
                    <p class="client_name">{{ service_obj.name }}</p>
                  </span>

                  <span class="me-4 d-flex align-items-center">
                    <img class="me-1" src="assets/images/serivces/house.png" />
                    <p class="client_name">{{ service_obj.unit }}</p>
                  </span>

                  <div class="dropdown me-2">
                    <button
                      class="btn btn-secondary dropdown-toggle"
                      type="button"
                      id="dropdownMenuButton1"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {{ service_obj.status }}
                    </button>
                    <ul
                      class="dropdown-menu"
                      aria-labelledby="dropdownMenuButton1"
                    >
                      <li (click)="changeStatus('Pending')">
                        <a class="dropdown-item">Pending</a>
                      </li>
                      <li (click)="changeStatus('Active')">
                        <a class="dropdown-item">Active</a>
                      </li>
                      <li (click)="changeStatus('Closed')">
                        <a class="dropdown-item">Closed</a>
                      </li>
                      <li (click)="changeStatus('Complete')">
                        <a class="dropdown-item">Complete</a>
                      </li>
                    </ul>
                  </div>

                  <button
                    class="btn btn-danger"
                    type="button"
                    aria-expanded="false"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>

            <div class="d-flex align-items-center">
              <strong class="me-2">Technical Name:</strong>

              <div>
                <mat-form-field appearance="outline" class="w-100">
                  <mat-label>Technician</mat-label>
                  <mat-select
                    fullWidth
                    placeholder="Select technical name"
                    class="d-felx justify-content-center align-items-center"
                  >
                    <mat-option value="Adham eraky">
                      <strong> Adham eraky </strong>
                    </mat-option>

                    <mat-option>
                      <strong> Adham eraky </strong>
                    </mat-option>

                    <mat-option>
                      <strong> Adham eraky </strong>
                    </mat-option>
                  </mat-select>
                </mat-form-field>
              </div>
            </div>
          </mat-card-content>
        </mat-card>

        <div class="row mt-4">
          <div class="col-md-6">
            <div class="card">
              <div class="section">
                <h3>Client Information</h3>
                <div class="info-row">
                  <strong>Name</strong>
                  <span>Ahmed Ali</span>
                </div>
                <div class="info-row">
                  <strong>Phone Number</strong>
                  <span>0102399897987</span>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="section">
                <h3>Unit Information</h3>
                <div class="info-row">
                  <strong>Unit Name</strong>
                  <span>Unit A1</span>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="section">
                <h3>Service Information</h3>
                <div class="info-row">
                  <strong>Service Type</strong>
                  <span>AC Service</span>
                </div>
                <div class="info-row">
                  <strong>Request Time</strong>
                  <span>8 Aug, 9:00 AM</span>
                </div>
                <div class="info-row">
                  <strong>Appointment Date</strong>
                  <span>10 Aug, 9:00 AM</span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="card">
              <div class="section">
                <div>
                  <strong>Description</strong>
                  <br />
                  <div class="description">
                    Excepteur sint occaecat cupidatat non proident, sunt in
                    culpa qui officia deserunt mollit anim id es
                  </div>
                </div>
                <div class="mt-4">
                  <strong>Photos</strong>
                  <br />
                  <div class="d-flex align-items-center mt-2">
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ service_obj.servImage }}"
                    />
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ service_obj.servImage }}"
                    />
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ service_obj.servImage }}"
                    />
                    <img
                      class="me-3"
                      style="width: 60px; height: 60px"
                      src="{{ service_obj.servImage }}"
                    />
                  </div>
                </div>

                <div class="mt-4">
                  <strong>Voice</strong>
                  <br />
                  <div class="d-flex align-items-center mt-2">
                    <div class="audio-player">
                      <button class="play-button">
                        <span class="icon"></span>
                      </button>
                      <div class="progress-container">
                        <input
                          type="range"
                          class="progress-bar"
                          value="0"
                          max="100"
                        />
                        <span class="time">00:00 / 1:33</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-end">
          <button class="btn assignButton">Assign</button>
        </div>
      </mat-tab>
      <mat-tab label="Checkout">
        <div class="row mt-4">
          <div class="col-md-12">
            <div class="card">
              <div class="section">
                <div class="info-row">
                  <strong>Client Name</strong>
                  <span>Ahmed Ali</span>
                </div>
                <div class="info-row">
                  <strong>Phone Number</strong>
                  <span>0102399897987</span>
                </div>
                <div class="info-row">
                  <strong>Unit Name</strong>
                  <span>Unit 111</span>
                </div>
                <div class="info-row">
                  <strong>Date and Time</strong>
                  <span>8 Aug at 9:00 AM</span>
                </div>
              </div>
            </div>
            <div class="card">
              <div class="section" *ngIf="showAddingMicroServiceData">
                <div class="d-flex justify-content-between align-items-center">
                  <div>
                    <mat-form-field appearance="outline" class="w-100">
                      <mat-label>Microservice</mat-label>
                      <mat-select
                        fullWidth
                        placeholder="Select technical name"
                        class="d-felx justify-content-center align-items-center"
                      >
                        <mat-option value="Adham eraky">
                          <strong> Adham eraky </strong>
                        </mat-option>

                        <mat-option>
                          <strong> Adham eraky </strong>
                        </mat-option>

                        <mat-option>
                          <strong> Adham eraky </strong>
                        </mat-option>
                      </mat-select>
                    </mat-form-field>
                  </div>

                  <div
                    class="d-flex justify-content-between align-items-center"
                  >
                    <div class="me-5">
                      <strong class="me-2">Amount</strong>
                      <button
                        class="btn decrease me-2"
                        (click)="decreaseAmount()"
                      >
                        <mat-icon>-</mat-icon>
                      </button>
                      <strong class="me-2">{{ amount }}</strong>

                      <button class="btn decrease" (click)="increaseAmount()">
                        <mat-icon>+</mat-icon>
                      </button>
                    </div>

                    <div>
                      <strong class="me-2">Price</strong>
                      <button
                        class="btn decrease me-2"
                        (click)="decreasePrice()"
                      >
                        <mat-icon>-</mat-icon>
                      </button>
                      <strong class="me-2">{{ price }}</strong>

                      <button class="btn decrease" (click)="increasePrice()">
                        <mat-icon>+</mat-icon>
                      </button>
                    </div>
                  </div>

                  <button class="btn addMicroService" type="button">Add</button>
                </div>
              </div>

              <div class="section">
                <div
                  class="d-flex justify-content-end"
                  *ngIf="!showAddingMicroServiceData"
                >
                  <button class="btn addMicroService me-2" type="button">
                    <mat-icon>edit</mat-icon>
                  </button>

                  <button
                    class="btn addMicroService"
                    type="button"
                    (click)="showMicroServices()"
                  >
                    Add Microservice
                  </button>
                </div>
                <div class="info-row">
                  <strong>AC Installation</strong>
                  <span>100 $</span>
                </div>

                <div class="info-row">
                  <strong>AC Repair x2</strong>
                  <span>200 $</span>
                </div>
              </div>
            </div>
            <hr />
            <div>
              <div class="section">
                <div class="info-row">
                  <strong>Price</strong>
                  <span>300 $</span>
                </div>
                <div class="info-row">
                  <strong>Promo code</strong>
                  <span>ACG56</span>
                </div>
                <div class="info-row">
                  <strong>Discount</strong>
                  <span>-150 $</span>
                </div>

                <div class="info-row-final-price">
                  <strong>Final Price</strong>
                  <span>150 $</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="d-flex justify-content-end">
          <button class="btn assignButton">Submit</button>
        </div>
      </mat-tab>

      <mat-tab label="Chat room">
        <div class="card">
          <div class="chat-container">
            <!-- Message from Sender -->
            <div class="chat-message sender">
              <div class="message-content">
                <p>Hi, how are you?</p>
                <span class="time">12:01 PM</span>
              </div>
              <img
                src="../../../../assets/images/avatar.png"
                alt="Sender"
                class="profile-pic"
              />
            </div>

            <!-- Message from Receiver -->
            <div class="chat-message receiver">
              <img
                src="../../../../assets/images/adham2.jpg"
                alt="Receiver"
                class="profile-pic"
              />
              <div class="message-content">
                <p>I'm doing great, thanks! How about you?</p>
                <span class="time">12:02 PM</span>
              </div>
            </div>

            <!-- Message from Sender -->
            <div class="chat-message sender">
              <div class="message-content">
                <p>Hi, how are you?</p>
                <span class="time">12:01 PM</span>
              </div>
              <img
                src="../../../../assets/images/avatar.png"
                alt="Sender"
                class="profile-pic"
              />
            </div>

            <!-- Message from Receiver -->
            <div class="chat-message receiver">
              <img
                src="../../../../assets/images/adham2.jpg"
                alt="Receiver"
                class="profile-pic"
              />
              <div class="message-content">
                <p>I'm doing great, thanks! How about you?</p>
                <span class="time">12:02 PM</span>
              </div>
            </div>
            <!-- Message from Sender -->
            <div class="chat-message sender">
              <div class="message-content">
                <p>Hi, how are you?</p>
                <span class="time">12:01 PM</span>
              </div>
              <img
                src="../../../../assets/images/avatar.png"
                alt="Sender"
                class="profile-pic"
              />
            </div>

            <!-- Message from Receiver -->
            <div class="chat-message receiver">
              <img
                src="../../../../assets/images/adham2.jpg"
                alt="Receiver"
                class="profile-pic"
              />
              <div class="message-content">
                <p>I'm doing great, thanks! How about you?</p>
                <span class="time">12:02 PM</span>
              </div>
            </div>
          </div>

          <!-- Input field for sending new messages -->
          <div class="chat-input">
            <input type="text" placeholder="Send message" />
            <button>Send</button>
          </div>
        </div>
      </mat-tab>
      <mat-tab label="Logs"> </mat-tab>
    </mat-tab-group>
  </section>
</section>
